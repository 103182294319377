import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { getReportFromLocalStorage, setReportToLocalStorage } from '../data-storage/text-report';

import { ITextReport, TAddressItem, TCity, TMainReportInfo, TProjectInfo, TReportStats, TStreet, TUserInfo } from "../types/text-report";


export type ITextReportState = ITextReport & { isReportSent: boolean, processReportSending: boolean };

const initialState: ITextReportState = {
	id: null,
	project: null,
	user: null,
	redirect: null,
	comment: null,
	cities: [],
	streets: [],
	addresses: [],
	stats: {
		buildings: 0,
		entrances: 0,
		flyers: 0,
	},
	isReportSent: false,
	processReportSending: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//     'counter/fetchCount',
//     async (amount: number) => {
//         const response = await fetchCount(amount);
//         // The value we return becomes the `fulfilled` action payload
//         return response.data;
//     }
// );

const calculateStreetStats = (state: ITextReportState, streetId: string) => {
	return state.addresses.reduce((stats, address) => {
		if (streetId === address.parentId) {
			stats.buildings++;
			stats.entrances += address.entrances;
			stats.flyers += address.flyers;
		}

		return stats;
	}, {
		buildings: 0,
		entrances: 0,
		flyers: 0,
	} as TReportStats);
}

const calculateCityStats = (state: ITextReportState, cityId: string) => {
	const streetIds = state.streets.reduce((idsArr, street) => {
		if (street.parentId === cityId) {
			idsArr.push(street.id);
		}

		return idsArr;
	}, []);

	return state.addresses.reduce((stats, address) => {
		if (streetIds.includes(address.parentId)) {
			stats.buildings++;
			stats.entrances += address.entrances;
			stats.flyers += address.flyers;
		}

		return stats;
	}, {
		buildings: 0,
		entrances: 0,
		flyers: 0,
	} as TReportStats);
}

const calculateReportStats = (state: ITextReportState) => {
	return state.addresses.reduce((stats, address) => {
		stats.buildings++;
		stats.entrances += address.entrances;
		stats.flyers += address.flyers;

		return stats;
	}, {
		buildings: 0,
		entrances: 0,
		flyers: 0,
	} as TReportStats);
}

export const textReportSlice = createSlice({
	name: 'textReport',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		initReportState: (state, action: PayloadAction<TMainReportInfo>) => {
			const	tokenData = action.payload;
			const reportData = getReportFromLocalStorage(tokenData.id);

			console.log('tokenData', tokenData);
			
			if (reportData) {
				return {...state, ...(reportData as ITextReportState)};
			}

			return {...state, ...tokenData};
		},

		setReportSuccessfullySent: (state, action: PayloadAction<boolean>) => {
			if (action.payload) {
				const newState = {
					...initialState, 
					id: state.id,
					isReportSent: action.payload
				};
				setReportToLocalStorage(newState);
				return newState;
			}

			return state;
		},

		setProcessReportSending: (state, action: PayloadAction<boolean>) => {
			return {
				...state,
				processReportSending: action.payload
			}
		},

		setReportInfo: (state, action: PayloadAction<{ id: string; project: TProjectInfo; user: TUserInfo; }>) => {
			const newState = {
				...state,
				id: action.payload.id,
				project: action.payload.project,
				user: action.payload.user
			};

			setReportToLocalStorage(newState);

			return newState;
		},
		setReportComment: (state, action: PayloadAction<string>) => {
			const newState = {
				...state,
				comment: action.payload
			}

			setReportToLocalStorage(newState);

			return newState;
		},
		recalculateReportStats: (state) => {
			const newState = {
				...state,
				stats: calculateReportStats(state)
			}

			setReportToLocalStorage(newState);

			return newState;
		},
		addAddress: (state, action: PayloadAction<TAddressItem>) => {
			const newState = {
				...state,
				addresses: [...state.addresses, action.payload]
			};

			setReportToLocalStorage(newState);
			
			return newState;
		},
		deleteAddress: (state, action: PayloadAction<TAddressItem>) => {
			const cityId = state.streets.find(street => street.id === action.payload.parentId).parentId;

			const newState = {
				...state,
				addresses: state.addresses.filter(address => {
					if (address.id !== action.payload.id) {
						return true;
					}
					return false;
				})
			};

			newState.streets = newState.streets.map(street => {
				if (street.id === action.payload.parentId) {
					return {
						...street,
						stats: calculateStreetStats(newState, street.id)
					}
				}

				return street;
			});

			newState.cities = newState.cities.map(city => {
				if (city.id === cityId) {
					return {
						...city,
						stats: calculateCityStats(newState, cityId)
					}
				}

				return city;
			});

			setReportToLocalStorage(newState);

			return newState;
		},
		updateAddress: (state, action: PayloadAction<TAddressItem>) => {
			const cityId = state.streets.find(street => street.id === action.payload.parentId).parentId;

			const newState = {
				...state,
				addresses: state.addresses.map(address => {
					if (address.id === action.payload.id) {
						return action.payload;
					}

					return address;
				})
			};

			newState.streets = newState.streets.map(street => {
				if (street.id === action.payload.parentId) {
					return {
						...street,
						stats: calculateStreetStats(newState, street.id)
					}
				}

				return street;
			});

			newState.cities = newState.cities.map(city => {
				if (city.id === cityId) {
					return {
						...city,
						stats: calculateCityStats(newState, cityId)
					}
				}

				return city;
			});

			setReportToLocalStorage(newState);

			return newState;
		},

		addStreet: (state, action: PayloadAction<TStreet>) => {
			const newState = {
				...state,
				streets: [...state.streets, action.payload]
			}

			setReportToLocalStorage(newState);

			return newState;
		},
		deleteStreet: (state, action: PayloadAction<TStreet>) => {
			const newState = {
				...state,
				streets: state.streets.filter(street => street.id !== action.payload.id),
				addresses: state.addresses.filter(address => address.parentId !== action.payload.id)
			}

			newState.cities = newState.cities.map(city => {
				if (city.id === action.payload.parentId) {
					return {
						...city,
						stats: calculateCityStats(newState, action.payload.parentId)
					}
				}

				return city;
			});

			setReportToLocalStorage(newState);

			return newState;
		},
		updateStreet: (state, action: PayloadAction<TStreet>) => {
			const newState = {
				...state,
				streets: state.streets.map(street => {
					if (street.id === action.payload.id) {
						return action.payload;
					}

					return street;
				})
			}

			setReportToLocalStorage(newState);

			return newState;
		},

		addCity: (state, action: PayloadAction<TCity>) => {
			const newState = {
				...state,
				cities: [...state.cities, action.payload]
			}

			setReportToLocalStorage(newState);

			return newState;
		},
		deleteCity: (state, action: PayloadAction<TCity>) => {
			const streetIds = [];
			const newState = {
				...state,
				cities: state.cities.filter(city => city.id !== action.payload.id),
				streets: state.streets.filter(street => {
					if (street.parentId !== action.payload.id) {
						return true;
					}

					streetIds.push(street.id);
					return false;
				}),
				addresses: state.addresses.filter(address => !streetIds.includes(address.parentId))
			}

			setReportToLocalStorage(newState);

			return newState;
		},
		updateCity: (state, action: PayloadAction<TCity>) => {
			const newState = {
				...state,
				cities: state.cities.map(city => {
					if (city.id === action.payload.id) {
						return action.payload;
					}

					return city;
				})
			}

			setReportToLocalStorage(newState);

			return newState;
		}
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	// extraReducers: (builder) => {
	//     builder
	//         .addCase(incrementAsync.pending, (state) => {
	//             state.status = 'loading';
	//         })
	//         .addCase(incrementAsync.fulfilled, (state, action) => {
	//             state.status = 'idle';
	//             state.value += action.payload;
	//         })
	//         .addCase(incrementAsync.rejected, (state) => {
	//             state.status = 'failed';
	//         });
	// },
});

export const {
	initReportState,
	setReportInfo,
	setReportComment,
	recalculateReportStats,
	addAddress,
	deleteAddress,
	updateAddress,
	addStreet,
	deleteStreet,
	updateStreet,
	addCity,
	deleteCity,
	updateCity,
	setReportSuccessfullySent,
	setProcessReportSending,
} = textReportSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectRedirect = (state: RootState) => state.textReport.redirect;
export const selectProcessReportSending = (state: RootState) => state.textReport.processReportSending;
export const selectIsReportSent = (state: RootState) => state.textReport.isReportSent;
export const selectReportState = (state: RootState) => state.textReport;
export const selectProjectInfo = (state: RootState) => ({
	project: state.textReport.project,
	user: state.textReport.user,
	comment: state.textReport.comment,
});
export const selectReportStats = (state: RootState) => state.textReport.stats;
export const selectProjectId = (state: RootState) => state.textReport.id;
export const selectProject = (state: RootState) => state.textReport.project;
export const selectUser = (state: RootState) => state.textReport.user;
export const selectComment = (state: RootState) => state.textReport.comment;
export const selectAllCities = (state: RootState) => state.textReport.cities;
export const selectCitiesById = (state: RootState, id: string) => state.textReport.cities.find(city => city.id === id);
export const selectStreetsByCityId = (state: RootState, cityId: string) => state.textReport.streets.filter(city => city.parentId === cityId);
export const selectAllAddresses = (state: RootState) => state.textReport.addresses;
export const selectAddressesByStreetId = (state: RootState, streetId: string) => state.textReport.addresses.filter(address => address.parentId === streetId);
export const selectAddressById = (state: RootState, addressId: string) => state.textReport.addresses.find(address => address.id === addressId);

export default textReportSlice.reducer;