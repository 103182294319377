import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from "./store/store";

import 'nprogress/nprogress.css';
import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';

ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
					<BrowserRouter>
							<App />
					</BrowserRouter>
        </Provider>
    </HelmetProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
