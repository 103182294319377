import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import textReportReducer from "./text-report-slice";

export const store = configureStore({
    reducer: {
        textReport: textReportReducer,
    },
    devTools: process.env.NODE_ENV !== 'production'
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;